
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  height: 100%;
}

.title {
  font-weight: 600;
  font-size: 200rem;
  background: linear-gradient(45deg, $info 20%, $danger 40%, $danger 60%, $info 80%);
  background-color: $info;
  background-repeat: repeat;
  background-size: 200% auto;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
